/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Head from 'next/head';
import Header4 from '../../components/Header/Header4';
import CustomFooter from '../../components/Footer/CustomFooter';
import Header8 from '../../components/Header/Header8';
import Footer2 from '../../components/Footer/Footer2';
import Sub_footer from '../../components/Footer/Sub_footer';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  const [showUserMenus, setShowUserMenus] = useState(false);
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={`${pageDescription}`} />
        <meta property="og:title" content={`${pageTitle}`} key="title" />
      </Head>
      <div>
        <Header8 />
        <div className="  min-h-screen   mx-auto  ">
          <div>{children}</div>
        </div>
        <div className="    min-h-[200px]  left-0 bottom-0  ">
          <div>
            <Sub_footer title={'Add Course'} />
          </div>
          <div className="  bg-footer mt-10 ">
            <Footer2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
