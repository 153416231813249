/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
const Variants2 = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  listing_details,
}) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  return (
    <div className="bg-white rounded-card   w-full min-h-[66px]    p-[16px]   ">
      <p className="text-[#121212] text-sm  font-semibold leading-4 ">
        {constant.variant_title(MARKETPLACE_MODULES)}
      </p>
      <div className="  mt-5  flex  items-center gap-5   flex-wrap ">
        {variants.map((item) => {
          return (
            <div
              key={item.id}
              className={
                selectedVariant !== item.id
                  ? ' min-w-[100px] px-4 py-2   rounded-card   overflow-hidden  flex items-center shadow-c-sm  border border-seceondary cursor-pointer'
                  : ' min-w-[100px] px-4 py-2   rounded-card   overflow-hidden  flex items-center  first-of-type:shadow-c-sm cursor-pointer transition duration-700  border border-seceondary  ring ring-primary ring-offset-1'
              }
              onClick={() => setSelectedVariant(item)}
            >
              <div className="">
                <p className="text-black font-semibold">{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Variants2;
