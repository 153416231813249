/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/listingSlice';
import { homeCollections } from '../../../store/feature/homeSlice';

import ListingCard2 from '../../Shared/Cards/ListingCard2';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const SimpleLatestListings = ({ products }) => {
  const { login, auth_key } = useSelector(authSelector);
  // const { isSuccess } = useSelector(listingSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  const like = (id, isLiked) => {
    if (login) {
      dispatch(
        listingLike({
          id,
          isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(homeCollections({ authKey: auth_key }));
        }
      });
    } else {
      router.push('/sign-in');
    }
  };

  return (
    <div className="mt-1">
      <div className="grid grid-rows-2	grid-cols-2  sm:grid-cols-3  md:grid-cols-4 gap-5">
        {products?.listings.map((item, index, array) => {
          return (
            index < 4 && (
              <div key={item.id} className="row-span-2">
                <ListingCard2 item={item} like={like} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SimpleLatestListings;
