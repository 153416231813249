import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import Banner from '../../components/home/Banner/Banner';
import Banner3 from '../../components/home/Banner/Banner3';
import Banner2 from '../../components/home/Banner/Bannner2';
import Categories from '../../components/home/Categories/Categories';
import LatestEvent from '../../components/home/LatestListings/LatestEvent';
import StoresForFollow from '../../components/home/Stores/StoresForFollow';
import LatestListings from '../../components/home/LatestListings/LatestListings';
import SimpleLatestListings from '../../components/home/LatestListings/SimpleLatestListing';
import {
  clearListingState,
  getAllListings,
  listingDetails,
  listingSelector,
} from '../../store/feature/listingSlice';
import SimpleListingsCard from '../../components/home/Card/SimpleListingsCard';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import axios from 'axios';
import Editor from 'rich-markdown-editor';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const HomePageLayout = () => {
  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [all_listings_details, set_all_listings_details] = useState(null);

  useEffect(() => {
    dispatch(
      homeCollections({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
      })
    );
    dispatch(
      AllPromoBanners({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
        bodyParam: { placement: 'footer' },
      })
    );
    dispatch(
      getAllListings({
        prams: { page: 1, per_page: 10, status: 2, include: 'attributes' },
        authKey: auth_key,
      })
    );
  }, [auth_key, dispatch]);

  const {
    collections,
    isFetching,
    isSuccess,
    errorMessage,
    categories,
    promo_banners,
    page_promo_banners,
  } = useSelector(homeSelector);

  const { general_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);

  const { listings, total_records, page } = useSelector(listingSelector);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  useEffect(() => {
    if (listings?.length > 0) {
      var all_data = [];
      for (let i = 0; i < listings.length; i++) {
        const element = listings[i];
        axios.get(`/api/l/${element.id}`).then((res) => {
          all_data.push(res.data);
          if (all_data.length === listings.length) {
            set_all_listings_details([...all_data]);
          }
        });
      }
    }
  }, [listings]);

  console.log(all_listings_details);
  return (
    <div className="">
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0  w-screen mt-5 ">
            <div className="w-full  xs:w-[500px] mx-auto">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div>
        <Banner2 banners={promo_banners} />
      </div>
      <div
        key={Math.random()}
        className="max-w-[1400px] mx-auto px-3 lg:px-9 text-center mt-7"
      >
        <h2 className="text-[32px] text-black font-bold pr-4">
          {general_configs?.header_text}
        </h2>
        <p className=" mt-2 text-[16px] text-black font-normal w-5/6 mx-auto">
          {general_configs?.header_description && (
            <article className="prose  max-w-full prose-red	">
              <Editor
                defaultValue={general_configs?.header_description}
                readOnly={true}
              />
            </article>
          )}
        </p>
      </div>
      {/* {general_configs?.home_categories_enabled && (
         <div>
           <Categories categories={categories} />
         </div>
       )} */}
      {collections?.map((collection) => {
        const scope_type = collection.scope_type;
        if (scope_type === 1 && MARKETPLACE_FLAVOURS === 1) {
          return (
            <div key={Math.random()}>
              <StoresForFollow stores={collection} />
            </div>
          );
        }
        if (scope_type === 4) {
          return (
            <div
              key={Math.random()}
              className="max-w-[1400px] mx-auto px-3 lg:px-9 mt-12"
            >
              <SimpleLatestListings products={collection} />
            </div>
          );
        }
      })}
      <div className="mt-9">
        <Banner3 />
      </div>

      <div className="mt-12 max-w-[1400px] mx-auto px-3 lg:px-9  ">
        <SimpleListingsCard
          all_listings_details={all_listings_details}
          setShowError={setShowError}
          setError_message={setError_message}
        />
      </div>
    </div>
  );
};

export default HomePageLayout;
