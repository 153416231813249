import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getThumbnailImage } from '../../components/Shared/Constant/Constant';
import Home_filter from '../../components/home/Filter/Home_filter';
import Expert_directory_accounts from '../../components/home/Stores/Expert_directory_accounts';
import { useRouter } from 'next/router';
import axios from 'axios';
import Sub_footer from '../../components/Footer/Sub_footer';

const HomePageLayout = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);
  const [accounts, setAccounts] = useState(null);
  const [page, setPage] = useState(1);
  const [total_records, setTotal_records] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  //
  useEffect(() => {
    dispatch(
      homeCollections({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
      })
    );
    dispatch(
      AllPromoBanners({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
        bodyParam: { medium: 'web' },
      })
    );
  }, [auth_key, dispatch]);

  //
  const queries = router.query;
  console.log(queries);

  // fetch accounts
  useEffect(() => {
    const params = router?.query?.page
      ? { ...router.query, type: 'accounts', status: 2 }
      : { ...router.query, page: 1, type: 'accounts', status: 2 };
    axios
      .get('/api/a', {
        params: params,
      })
      .then((res) => {
        setAccounts(res.data.accounts);
        setPage(res.data.page);
        setTotal_records(res.data.total_records);

        const total_page = Math.ceil(res.data.total_records / 30);
        if (Number(total_records) > 30) {
          setPageCount(total_page);
        }
      });
  }, [router]);

  //
  const moreAccounts = (data) => {
    router.push({
      query: {
        ...router.query,
        page: Number(data.selected) + 1,
        type: 'accounts',
        status: 2,
      },
    });
  };

  // Data from store
  const { collections, categories, page_promo_banners } =
    useSelector(homeSelector);

  const { general_configs } = useSelector(configsSelector);
  return (
    <>
      <div className="mt-5">
        <section>
          <div className=" px-5 sm:px-6 md:px-16 grid lg:grid-cols-2  h-full p-6 md:p-0  md:h-[417px] items-center bg-[#EFEEEA]">
            <div className="flex   justify-start  md:justify-end  items-center  md:pr-10">
              <div className="  max-w-[514px]">
                <h2 className="h2 text-black font-bold pr-4">
                  {general_configs?.header_text}
                </h2>
                <p className=" paragraph  md:font-medium  mt-4   text-black   ">
                  {general_configs?.header_description}
                </p>
              </div>
            </div>
            <div className=" hidden   lg:flex items-center justify-start pl-10">
              {page_promo_banners?.length > 0 && (
                <div className="relative   w-[400px]  ">
                  <img
                    src={page_promo_banners[0]?.image_path}
                    className="  absolute  max-w-[100%]  top-[50%] left-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="mt-6 md:mt-12  px-5 sm:px-6 md:px-16 flex   justify-center">
          <div className=" w-[90em]  flex flex-row">
            <div className=" hidden lg:block flex-grow-[1.5]  flex-shrink  min-w-[350px] max-w-[400px]">
              <Home_filter />
            </div>

            <div className="flex-grow-[4] flex-shrink " key={Math.random()}>
              <Expert_directory_accounts
                accounts={accounts}
                total_records={total_records}
                moreAccounts={moreAccounts}
                pageCount={pageCount}
                page={page}
              />
            </div>
          </div>
        </section>


      </div>
    </>
  );
};

export default HomePageLayout;
