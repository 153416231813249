/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';

const AccountProfile = ({ account_details, follow }) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  return account_details !== null ? (
    <div className=" w-full  min-h-[100px] bg-white rounded-card   p-[25px] relative shadow-c-sm">
      <div className=" w-5/6 ">
        <h4 className=" mt-[9px] h4 text-primary font-medium ">
          {account_details?.name}
        </h4>
        <p className=" mt-1 flex items-center flex-wrap">
          <span className="text-default_gray text-base font-medium">
            <p className=" text-default_gray text-sm">
              @{account_details?.user?.first_name}
            </p>
          </span>
        </p>
        <p className=" mt-3 font-medium text-base text-black flex flex-wrap  gap-x-10 gap-y-3">
          {account_details?.location?.city && (
            <a
              href={`https://maps.google.com/?q=${account_details?.coordinates?.latitude},${account_details?.coordinates?.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <a
                href={`https://maps.apple.com/maps?q=${account_details?.coordinates?.latitude},${account_details?.coordinates?.longitude}`}
                target="_blank"
                rel="noreferrer"
                className="flex  items-center gap-1 hover:text-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                {account_details?.location?.city}
              </a>
            </a>
          )}
          {/* {account_details?.categories[0]?.name && (
                <span>{account_details?.categories[0]?.name}</span>
              )} */}
          {Number(account_details?.total_followers) > 0 && (
            <span>
              {account_details?.total_followers}{' '}
              {Number(account_details?.total_followers) > 1
                ? 'Followers'
                : 'Follower'}
            </span>
          )}
          {Number(account_details?.total_listings) > 0 && (
            <span>
              {account_details?.total_listings}{' '}
              {Number(account_details?.total_listings) > 1
                ? 'Listings'
                : 'Listing'}
            </span>
          )}
        </p>
      </div>

      <div className=" absolute top-0 right-0 mt-6 mr-6">
        <button
          className={[
            '  w-[145px] h-[35px] flex justify-center items-center border border-primary  rounded-button',
            account_details.following
              ? 'text-[#FFFFFF]  bg-primary'
              : 'text-primary   bg-transparent',
          ].join(' ')}
          onClick={() =>
            follow(account_details?.id, account_details?.following)
          }
        >
          {account_details?.following ? 'Following' : 'Follow'}
        </button>
      </div>
    </div>
  ) : (
    <div className=" min-h-[300px] border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4 mt-5">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4 mt-5">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
