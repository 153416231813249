import { TYPE_CONSTANT } from './constant/Web_constant';

// Product
import ProductHomePageLayout from './themes/product/HomePageLayout';
import ProductMainLayout from './themes/product/MainLayout';
import ProductCategoriesPageLayout from './themes/product/CategoriesPageLayout';
import ListingsPageLayout from './themes/product/ListingsPageLayout';
import SimilarListingsPageLayout from './themes/product/SimilarListingsPageLayout';
import CategoryListingsPageLayout from './themes/product/CategoryListingsPageLayout';
import ProductDetailsPageLayout from './themes/product/ProductDetailsPageLayout';
import ProductAllAccountsPageLayout from './themes/product/AllAccountsPageLayout';
import ProductAccountDetailsPageLayout from './themes/product/AccountDetailsPageLayout';
import ProductMyStorePageLayout from './themes/product/MyStorePageLayout';
import ProductCreateStorePageLayout from './themes/product/CreateStorePageLayout';
import ProductEditStorePageLayout from './themes/product/EditStorePageLayout';
import AddListingPageLayout from './themes/product/AddListingPageLayout';
import ProductEditProductPageLayout from './themes/product/EditProductPageLayout';
import ProductEditProfilePageLayout from './themes/product/EditProfilePageLayout';
import ProductSearchPageLayout from './themes/product/SearchPageLayout';
import ProductStoreOrdersPageLayout from './themes/product/StoreOrdersPageLayout';
import ProductStoreOrderDetailsPageLayout from './themes/product/StoreOrderDetailsPageLayout';
import ProductOrdersPageLayout from './themes/product/OrdersPageLayout';
import ProductOrderDetailsPageLayout from './themes/product/OrderDetailsPageLayout';
import ProductAddReviewPageLayout from './themes/product/AddReviewPageLayout';
import CheckoutPageLayout from './themes/product/CheckoutPageLayout';
import ProductInvitePageLayout from './themes/product/InvitePageLayout';
import ProductPayoutPageLayout from './themes/product/PayoutPageLayout';
import ProductWishListPageLayout from './themes/product/WishListPageLayout';
import CollectionListingsPageLayout from './themes/product/CollectionListingsPageLayout';

// Event
import EventHomePageLayout from './themes/event/HomePageLayout';
import EventMainLayout from './themes/event/MainLayout';
import EventCategoriesPageLayout from './themes/event/CategoriesPageLayout';
import EventListingsPageLayout from './themes/event/EventListingsPageLayout';
import SimilarEventListingsPageLayout from './themes/event/SimilarEventListingsPageLayout';
import EventCategoryListingsPageLayout from './themes/event/EventCategoryListingsPageLayout';
import EventDetailsPageLayout from './themes/event/EventDetailsPageLayout';
import EventAllAccountsPageLayout from './themes/event/AllAccountsPageLayout';
import EventAccountDetailsPageLayout from './themes/event/AccountDetailsPageLayout';
import EventMyStorePageLayout from './themes/event/MyStorePageLayout';
import EventCreateStorePageLayout from './themes/event/CreateStorePageLayout';
import EventEditStorePageLayout from './themes/product/EditStorePageLayout';
import AddEventPageLayout from './themes/event/AddEventPageLayout';
import EventEditProductPageLayout from './themes/event/EditProductPageLayout';
import EventEditProfilePageLayout from './themes/event/EditProfilePageLayout';
import EventSearchPageLayout from './themes/event/EventSearchPageLayout';
import EventStoreOrdersPageLayout from './themes/event/StoreOrdersPageLayout';
import EventStoreOrderDetailsPageLayout from './themes/event/StoreOrderDetailsPageLayout';
import EventOrdersPageLayout from './themes/event/OrdersPageLayout';
import EventOrderDetailsPageLayout from './themes/event/OrderDetailsPageLayout';
import EventAddReviewPageLayout from './themes/event/AddReviewPageLayout';
import EventCheckoutPageLayout from './themes/event/EventCheckoutPageLayout';
import EventInvitePageLayout from './themes/event/InvitePageLayout';
import EventPayoutPageLayout from './themes/event/PayoutPageLayout';
import EventWishListPageLayout from './themes/event/WishListPageLayout';
import EventCollectionListingsPageLayout from './themes/event/CollectionListingsPageLayout';

// SAAS
import SaasHomePageLayout from './themes/saas/CustomHomePageLayout';
import SaasMainLayout from './themes/saas/CustomLayout';
import SaasCategoriesPageLayout from './themes/saas/CategoriesPageLayout';
import CustomListingsPageLayout from './themes/saas/CustomListingPageLayout';
import SimilarCustomListingsPageLayout from './themes/saas/SimilarCustomListingsPageLayout';
import SaasCategoryListingsPageLayout from './themes/saas/CategoryListingsPageLayout';
import CustomProductDetailsPageLayout from './themes/saas/CustomProductDetailsPageLayout';
import SaasAllAccountsPageLayout from './themes/saas/AllAccountsPageLayout';
import CustomAccountDetailsPageLayout from './themes/saas/CustomAccountDetailsPageLayout';
import CustomMyStorePageLayout from './themes/saas/CustomMyStorePageLayout';
import CustomCreateStorePageLayout from './themes/saas/CustomCreateStorePageLayout';
import CustomEditStorePageLayout from './themes/saas/CustomEditStorePageLayout';
import AddCustomListingPageLayout from './themes/saas/AddCUstomListingPageLayout';
import CustomEditProductPageLayout from './themes/saas/CustomEditProductPageLayout';
import SaasEditProfilePageLayout from './themes/saas/EditProfilePageLayout';
import SaasSearchPageLayout from './themes/saas/SearchPageLayout';
import SaasStoreOrdersPageLayout from './themes/saas/StoreOrdersPageLayout';
import SaasStoreOrderDetailsPageLayout from './themes/saas/StoreOrderDetailsPageLayout';
import SaasOrdersPageLayout from './themes/saas/OrdersPageLayout';
import SaasOrderDetailsPageLayout from './themes/saas/OrderDetailsPageLayout';
import SaasAddReviewPageLayout from './themes/saas/AddReviewPageLayout';
import SaasCollectionListingsPageLayout from './themes/saas/CollectionListingsPageLayout';

// Crowdfunding
import CrowdfundingMainLayout from './themes/Crowdfunding/MainLayout';
import CrowdfundingHomePageLayout from './themes/Crowdfunding/HomePageLayout';
import CrowdfundingCategoriesPageLayout from './themes/Crowdfunding/CategoriesPageLayout';
import CrowdfundingListingsPageLayout from './themes/Crowdfunding/ListingsPageLayout';
import CrowdfundingSimilarListingsPageLayout from './themes/Crowdfunding/SimilarListingsPageLayout';
import CrowdfundingCategoryListingsPageLayout from './themes/Crowdfunding/CategoryListingsPageLayout';
import CrowdfundingProductDetailsPageLayout from './themes/Crowdfunding/ProductDetailsPageLayout';
import CrowdfundingAllAccountsPageLayout from './themes/Crowdfunding/AllAccountsPageLayout';
import CrowdfundingAccountDetailsPageLayout from './themes/Crowdfunding/AccountDetailsPageLayout';
import CrowdfundingCheckoutPageLayout from './themes/Crowdfunding/CheckoutPageLayout';
import CrowdfundingCollectionListingsPageLayout from './themes/Crowdfunding/CollectionListingsPageLayout';

// Art & craft
import EtsyMainLayout from './themes/Art & Craft/MainLayout';
import EtsyHomePageLayout from './themes/Art & Craft/HomePageLayout.jsx';
import EtsyListingDetailsPageLayout from './themes/Art & Craft/ListingDetailsPageLayout.jsx';

// Simple
import SimpleMainLayout from './themes/simple/MainLayout';
import SimpleProductDetailsPageLayout from './themes/simple/ProductDetailsPageLayout';
import SimpleHomePageLayout from './themes/simple/HomePageLayout.jsx';
import SimpleCheckoutPageLayout from './themes/simple/CheckoutPageLayout';
import SimpleOrdersPageLayout from './themes/simple/OrdersPageLayout';
import SimpleOrderDetailsPageLayout from './themes/simple/OrderDetailsPageLayout';
import SimpleAddReviewPageLayout from './themes/simple/AddReviewPageLayout';
import SimpleEditProfilePageLayout from './themes/simple/EditProfilePageLayout';
import SimpleSimilarListingsPageLayout from './themes/simple/SimilarListingsPageLayout';
import PaymentPageLayout from './themes/common_layouts/PaymentPageLayout';

// expert directory theme
import ExpertMainLayout from './themes/expert_directory/MainLayout';
import ExpertHomePageLayout from './themes/expert_directory/HomePageLayout';
import ExpertAccountDetailsPageLayout from './themes/expert_directory/AccountDetailsPageLayout.jsx';

// Integrations Theme
import IntegrationsMainLayout from './themes/Integration/MainLayout';
import IntegrationsHomePageLayout from './themes/Integration/HomePageLayout';

module.exports = {
  
  // home Page
  home_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductHomePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventHomePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasHomePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EtsyHomePageLayout />
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CrowdfundingHomePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SimpleHomePageLayout />
          </SimpleMainLayout>
        );
        break;
      case 7:
        return (
          <ExpertMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ExpertHomePageLayout />
          </ExpertMainLayout>
        );
        break;
      case 8:
        return (
          <IntegrationsMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <IntegrationsHomePageLayout />
          </IntegrationsMainLayout>
        );
        break;

      default:
        return default_layout(<ProductHomePageLayout />);
    }
  },

  //Listing Categories Page:
  all_listing_categories_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductCategoriesPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventCategoriesPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasCategoriesPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductCategoriesPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CrowdfundingCategoriesPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductCategoriesPageLayout />);
    }
  },

  //all_listings_page Page:
  all_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <ListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <EventListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CustomListingsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ListingsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CrowdfundingListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductCategoriesPageLayout />);
    }
  },

  // Similar All Listing Page:
  similar_all_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarEventListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarCustomListingsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <SimilarListingsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CrowdfundingSimilarListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimpleSimilarListingsPageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<SimilarListingsPageLayout />);
    }
  },

  // Collection Listings Page:
  collection_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CollectionListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <EventCollectionListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SaasCollectionListingsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <CollectionListingsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CrowdfundingCollectionListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<CollectionListingsPageLayout />);
    }
  },

  // Category Listings Page:
  category_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <SaasCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <CategoryListingsPageLayout
                pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
                pageDescription={
                  TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION
                }
              />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(
          <CategoryListingsPageLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
          />
        );
    }
  },

  // listing_details_page:
  listing_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <CustomProductDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <EtsyListingDetailsPageLayout />
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingProductDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleProductDetailsPageLayout />
          </SimpleMainLayout>
        );
        break;

      default:
        return default_layout(<CustomProductDetailsPageLayout />);
    }
  },

  // all_accounts_page:
  all_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <ProductAllAccountsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <EventAllAccountsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <SaasAllAccountsPageLayout />
          </SaasMainLayout>
        );
        break;

      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductAllAccountsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <CrowdfundingAllAccountsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductAllAccountsPageLayout />);
    }
  },

  // accounts_details_page:
  accounts_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductAccountDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventAccountDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <CustomAccountDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductAccountDetailsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingAccountDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 7:
        return (
          <ExpertMainLayout>
            <ExpertAccountDetailsPageLayout />
          </ExpertMainLayout>
        );
        break;
      default:
        return default_layout(<ProductAccountDetailsPageLayout />);
    }
  },

  // checkout_page:
  checkout_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CheckoutPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventCheckoutPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <CheckoutPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CrowdfundingCheckoutPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimpleCheckoutPageLayout />
          </SimpleMainLayout>
        );
        break;

      default:
        return default_layout(<CheckoutPageLayout />);
    }
  },

  // my_store_page
  my_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductMyStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventMyStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CustomMyStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductMyStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductMyStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductMyStorePageLayout />);
    }
  },

  // create_store_page
  create_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductCreateStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventCreateStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CustomCreateStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductCreateStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductCreateStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductCreateStorePageLayout />);
    }
  },

  // edit_store_page
  edit_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventEditStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CustomEditStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductEditStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductEditStorePageLayout />);
    }
  },

  // add_listing_page
  add_listing_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <AddListingPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <AddEventPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <AddCustomListingPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <AddListingPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<AddListingPageLayout />);
    }
  },

  // edit_listing_page
  edit_listing_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditProductPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventEditProductPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <CustomEditProductPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductEditProductPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditProductPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductEditProductPageLayout />);
    }
  },

  // edit_profile_page
  edit_profile_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditProfilePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventEditProfilePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasEditProfilePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductEditProfilePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductEditProfilePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <SimpleEditProfilePageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<ProductEditProfilePageLayout />);
    }
  },
  // search_page
  search_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductSearchPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventSearchPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasSearchPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductSearchPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      default:
        return default_layout(<ProductSearchPageLayout />);
    }
  },

  // account orders
  account_orders: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductStoreOrdersPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventStoreOrdersPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasStoreOrdersPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductStoreOrdersPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductStoreOrdersPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductStoreOrdersPageLayout />);
    }
  },

  // account order details
  account_order_details: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductStoreOrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventStoreOrderDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasStoreOrderDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductStoreOrderDetailsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductStoreOrderDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductStoreOrderDetailsPageLayout />);
    }
  },

  // orders
  orders: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrdersPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventOrdersPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasOrdersPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductOrdersPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrdersPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SimpleOrdersPageLayout />
          </SimpleMainLayout>
        );
        break;

      default:
        return default_layout(<ProductOrdersPageLayout />);
    }
  },

  // order details
  profile_order_details: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductOrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventOrderDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasOrderDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SimpleOrderDetailsPageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<ProductOrderDetailsPageLayout />);
    }
  },

  // Add review
  add_review: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductAddReviewPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventAddReviewPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SaasAddReviewPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductAddReviewPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductAddReviewPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <SimpleAddReviewPageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<ProductAddReviewPageLayout />);
    }
  },

  // Invite page
  Invite_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductInvitePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventInvitePageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <ProductInvitePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductInvitePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="mt-4">
              <ProductInvitePageLayout />
            </div>
          </SimpleMainLayout>
        );
      default:
        return default_layout(<ProductInvitePageLayout />);
    }
  },

  // Payment  page
  payment_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <PaymentPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <PaymentPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              {' '}
              <PaymentPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <PaymentPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <div className="my-5">
              <PaymentPageLayout />
            </div>
          </SimpleMainLayout>
        );
        break;

      default:
        return default_layout(<PaymentPageLayout />);
    }
  },

  // Payout  page
  payout_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductPayoutPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventPayoutPageLayout />
          </EventMainLayout>
        );
        break;

      default:
        return default_layout(<ProductPayoutPageLayout />);
    }
  },
  // Wishlist  page
  wishlist_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <ProductWishListPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <EventWishListPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-9 mt-3">
              <ProductWishListPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_TITLE}
            pageDescription={TYPE_CONSTANT.META_DESCRIPTIONS}
          >
            <div className="mt-3 max-w-[1400px] mx-auto px-3 lg:px-9  ">
              <ProductWishListPageLayout />
            </div>
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<ProductWishListPageLayout />);
    }
  },
};

// Default Layout function
const default_layout = (page) => {
  switch (Number(TYPE_CONSTANT.THEME)) {
    case 1:
      return <ProductMainLayout>{page}</ProductMainLayout>;
      break;
    case 2:
      return <EventMainLayout>{page}</EventMainLayout>;
      break;
    case 3:
      return <SaasMainLayout>{page}</SaasMainLayout>;
      break;
    case 4:
      return <EtsyMainLayout>{page}</EtsyMainLayout>;
      break;
    case 5:
      return <CrowdfundingMainLayout>{page}</CrowdfundingMainLayout>;
      break;

    case 6:
      return <SimpleMainLayout>{page}</SimpleMainLayout>;
      break;
    case 7:
      return (
        <ExpertMainLayout>
          <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
        </ExpertMainLayout>
      );
      break;
    case 8:
      return (
        <IntegrationsMainLayout>
          <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
        </IntegrationsMainLayout>
      );
      break;

    default:
      return <ProductMainLayout>{page}</ProductMainLayout>;
  }
};
