/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moreImage from '../../../assets/Images/Categories/Menu.png';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const Categories2 = ({ categories }) => {
  return (
    <div className="relative">
      <div className=" max-w-[1400px] mx-auto px-9  flex justify-center">
        <h1 className=" h2 text-center p-5">
          {TYPE_CONSTANT?.GENERAL_CONFIGS?.header_text
            ? TYPE_CONSTANT?.GENERAL_CONFIGS?.header_text
            : 'Finds the best deals'}
        </h1>
      </div>
      <div
        className="max-w-[1400px] mx-auto     flex justify-center flex-wrap   gap-12    
      "
      >
        <div className="absolute top-0 bg-primary h-[75%] md:h-[60%]  lg:h-[50%] w-full -z-10"></div>

        {categories?.map((item, index) => {
          const query =
            item.name !== 'All Categories'
              ? {
                  name: item.name.replace(/\s/g, '-'),
                  category_id: item.id,
                  page: 1,
                }
              : '';

          return (
            index < 8 && (
              <Link
                key={Math.random()}
                href={{
                  pathname: `${
                    item.name !== 'All Categories' ? '/lc/[name]' : '/lc'
                  }`,
                  query,
                }}
                passHref
              >
                <a className=" w-[84px]  md:w-[94px]  lg:w-[114px]    hover:text-primary   cursor-pointer  group ">
                  <div className="  relative my-2   ">
                    <img
                      className="  w-[54px] h-[54px] md:w-[94px]  md:h-[94px] lg:w-[114px]  lg:h-[114px]  rounded-full object-cover bg-transparent shadow-sm  border-0 transition duration-500 ease-in-out scale-95 group-hover:scale-100"
                      src={getThumbnailImage(item.image_path)}
                      alt=""
                    />
                  </div>
                  <p className=" pb-1 w-[84px]  md:w-[94px]  lg:w-[114px]  text-base  md:text-lg  md:font-bold  border-b-2 border-transparent    text-center group-hover:border-primary py-1">
                    {item.name}
                  </p>
                </a>
              </Link>
            )
          );
        })}
      </div>
    </div>
  );
};

export default Categories2;
