import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import Banner from '../../components/home/Banner/Banner';
import Banner2 from '../../components/home/Banner/Bannner2';
import Categories from '../../components/home/Categories/Categories';
import LatestEvent from '../../components/home/LatestListings/LatestEvent';
import LatestListings from '../../components/home/LatestListings/LatestListings';
import StoresForFollow from '../../components/home/Stores/StoresForFollow';
import Categories2 from '../../components/home/Categories/Categories2';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const HomePageLayout = () => {
  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);

  useEffect(() => {
    dispatch(
      homeCollections({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
      })
    );
    dispatch(
      AllPromoBanners({
        authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
        bodyParam: { placement: 'footer' },
      })
    );
  }, [auth_key, dispatch]);

  const {
    collections,
    isFetching,
    isSuccess,
    errorMessage,
    categories,
    promo_banners,
    page_promo_banners,
  } = useSelector(homeSelector);

  return (
    <div className="">
      <div className=" mb-[52px]  ">
        <Categories2 categories={categories} />
      </div>
      {/* <div>
        <Banner2 banners={promo_banners} />
      </div> */}
      {collections?.map((collection) => {
        const scope_type = collection.scope_type;

        if (scope_type === 4) {
          return (
            <div key={Math.random()} className="max-w-[1400px] mx-auto px-9 my-20 ">
              <LatestListings products={collection} />
            </div>
          );
        }
      })}
      {/* <div>
        <Banner2 banners={page_promo_banners} />
      </div> */}
    </div>
  );
};

export default HomePageLayout;
