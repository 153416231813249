import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';

const Edit_listing_button = ({ listing_details }) => {
  const { user_details } = useSelector(authSelector);
  const router = useRouter();

  const [isOwner, setIsOwner] = useState();
  useEffect(() => {
    if (user_details?.id) {
      axios
        .get('/api/a/my_account', {
          params: {
            page: 1,
            type: 'accounts',
            user_id: user_details.id,
          },
        })
        .then((res) => {
          if (res?.data?.accounts[0].id == listing_details.account_id) {
            setIsOwner(true);
          } else {
            setIsOwner(false);
          }
        });
    }
  }, [listing_details, user_details]);

  return isOwner ? (
    <div>
      <button
        className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
        onClick={() => {
          if (listing_details.active) {
            router.push({
              pathname: '/a/edit-listing',
              query: {
                product_id: listing_details.id,
                account_id: listing_details.account_id,
              },
            });
          } else {
            console.log('Listing is not active');
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>{' '}
        Edit Listing
      </button>
    </div>
  ) : (
    ''
  );
};

export default Edit_listing_button;
