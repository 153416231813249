/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { useRouter } from 'next/dist/client/router';

import Link from 'next/link';

const CustomAccountButton = ({ attributes }) => {
  const router = useRouter();
  const link = attributes?.filter((it) => it.name === 'External Link');

  return (
    link?.length > 0 && (
      <>
        <div className=" w-full flex justify-center items-center">
          <Link href={link[0].values[0]}>
            <a
              className=" w-full text-center py-3 px-10 bg-primary rounded-button text-white"
              target="_blank"
            >
              Get In Touch
            </a>
          </Link>
        </div>
      </>
    )
  );
};

export default CustomAccountButton;
