/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import { changeDateFormat, getThumbnailImage } from '../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const ListingCard2 = ({ item, like }) => {
  const router = useRouter();
  const MARKETPLACE_MODULES = TYPE_CONSTANT.MARKETPLACE_MODULES;
  return (
    <div className="w-full h-full  relative group">
      <Link
        href={
          item.slug
            ? `/l/${item.slug}`
            : `/l/${item.id}-${item.title.replace(/\W/g, '-')}`
        }
      >
        <a
          className=" block w-full h-full   cursor-pointer   "
          // onClick={() =>
          //   router.push(`/l/${item.id}-${item.title.replace(/\W/g, '-')}`)
          // }
        >
          <div className=" w-full h-full    relative   ">
            <div className="aspect-w-5 aspect-h-4 relative">
              {item?.images?.length > 0 && (
                <img
                  className="absolute top-0 left-0  align-middle  rounded-image"
                  src={getThumbnailImage(item?.images[0])}
                  alt=""
                />
              )}
            </div>

            <p className=" absolute bottom-0 ml-4 mb-4 px-3 py-1 bg-white rounded-full  text-base font-medium text-gray-500  ">
              {item.list_price.formatted}
            </p>
          </div>

          {/* <div className="mt-2 pl-2">
            <p className="  text-sm ms:text-base xmd:text-lg leading-[15px] font-semibold text-primary">
              {item.title.length > 18
                ? item.title.substring(0, 18) + '..'
                : item.title}
            </p>
            <p className=" text-[14px]  ms:text-[16px] mb-[14px] leading-4 font-medium text-gray-500 mt-1">
              {item.list_price.formatted}
            </p>
          </div> */}
        </a>
      </Link>

      <div
        className=" h-[40px] w-[40px]  absolute right-0 top-0 mr-2 mt-2 cursor-pointer    transition duration-100   hidden group-hover:block"
        onClick={() => like(item.id, item.liked)}
      >
        {item.liked ? (
          <Image
            src={favorite}
            alt="follow button"
            layout="fill"
            objectFit="cover"
            require
          />
        ) : (
          <Image
            src={heartIcon}
            alt="follow button"
            layout="fill"
            objectFit="cover"
            require
          />
        )}
      </div>
    </div>
  );
};

export default ListingCard2;
